import React from 'react'
import Footer from './Footer'

function Contact() {
  return (
    <>
      <div className="flex justify-center items-center h-[100%] flex-col">
        <Footer />
      </div>
    </>
  )
}
export default Contact
